import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Grid, MenuItem, Select, InputLabel, FormControl, CircularProgress, Checkbox, ListItemText, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { Card } from 'react-bootstrap';
import axios from 'axios'; // Optional: You can use fetch instead
// import { newConfig } from '../../components/config/config';
import Urls from '@egovernments/digit-ui-libraries/src/services/atoms/urls';
import { useHistory } from "react-router-dom";

const JobDelegationForm = () => {
  // let initialStates = {};
  const history = useHistory();
  const [formData, setFormData] = useState({
    departmentId: '',
    location1: '',
    oldDesignationRole:'',
    empId: '',
    currentDesignation: "",
    employeeName: "",
    roles: [],
    district: '', 
    area: '',
    districtCode: '',
    startDate: '', 
    endDate: ''
  });
  const [searchTerm, setSearchTerm] = useState('');
const [employeeList, setEmployeeList] = useState([]);
const [filteredRolesInfor, setFilteredRolesInfor] = useState([]);
  const [loading, setLoading] = useState(true);
 
  // Sample data lists
  const departmentList = ['TCP', 'UD'];
  const tenantId = Digit.ULBService.getCurrentTenantId();
  const { data: EmployeeTypes = {}, isLoadings } = Digit.Hooks.hrms.useHrmsMDMS(tenantId, "egov-hrms", "HRMSRolesandDesignation") || {};
  const { data: cities, isLoading } = Digit.Hooks.useTenants();


  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'empId') {
      const selectedEmployee = employeeList.find(emp => emp.id === value);
      // console.log("selectedEmployee",selectedEmployee);
     setFormData({
        ...formData,
        empId: selectedEmployee?.id,
        oldDesignationRole :selectedEmployee.currentRoles.filter(role => role.primaryRole === true && role.code !== "EMPLOYEE"),
        currentDesignation: selectedEmployee?.currentRoles.map(role => role.name).join(', '), // Assuming currentRoles is an array
        employeeName: selectedEmployee?.name // Store the employee's name as well
      });
      setFilteredRolesInfor (selectedEmployee.currentRoles.filter(role => role.primaryRole === true && role.code !== "EMPLOYEE"));
    
    } else if (name === 'district') {
      const selectedDistrict = districts.find(district => district.name === value);
      setFormData({
        ...formData,
        district: selectedDistrict.name,
        districtCode: selectedDistrict.code
      });
    } else if (name === 'roles') {
      const selectedRoles = value.map(roleName =>
        filteredRoles?.find(role => role.name === roleName)
      );
      setFormData({ ...formData, roles: selectedRoles });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  let user = Digit.UserService.getUser();
  const authToken = Digit.UserService.getUser()?.access_token || null;
  // Simulate an API call on form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Creating the payload for the API
    const payload = {
      RequestInfo: {
        apiId: "Rainmaker",
        action: "_create",
        did: 1,
        key: "",
        msgId: "20170310130900|en_IN",
        ts: 0,
        ver: ".01",
        "authToken": authToken,
        userInfo: user?.info
      },
      JobDelegation: {
        departmentId: formData.departmentId,
        currentLocation: formData.location1,
        tenantId: formData.districtCode,
        empId: formData.empId,
        oldDesignationRole: formData.oldDesignationRole,
        roles: formData.roles.map(role => ({
          code: role.code,
          name: role.name,
          description: role.description
        })),
        location1: formData.district, // Add district to the payload
        location2: formData.area, // Add area to the payload
        startDate: formData.startDate, // Add startDate to the payload
        endDate: formData.endDate // Add endDate to the payload
      }
    };


    try {
      const response = await axios.post(`${Urls.hrms.UserJobdelegationSearch}`, payload);

      console.log('API Response:', response); // Log the response for now
      alert('Form submitted successfully!');
      history.push({
        pathname: `/digit-ui/employee`,
      })
      return response.data;
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit form.');
    }
  };

  const filteredRoles = EmployeeTypes?.MdmsRes?.["ACCESSCONTROL-ROLES"]?.roles?.filter(role =>
    role.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredTenant = EmployeeTypes?.MdmsRes?.tenant?.tenants?.filter(role =>
    role.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const districts = !isLoading && formData?.departmentId
    ? cities?.filter((city) => city.code?.includes(formData?.departmentId.toLowerCase()))
    : [];

  const selectedDistrict = districts?.find(district => district.name === formData?.district);
  const areas = selectedDistrict?.boundary || [];
  // const jobdelegationEmployeeResponse = Digit.Hooks.hrms.useHRMSJobdelegationEmployeeSearch(formData?.location1);
  console.log(districts, selectedDistrict, "dataMdmsNew");
  const handleEmployeeResponse = async (e) => {
    // e.preventDefault();

    // Creating the payload for the API
    const payload = {
      RequestInfo: {
        apiId: "Rainmaker",
        action: "_create",
        did: 1,
        key: "",
        msgId: "20170310130900|en_IN",
        ts: 0,
        ver: ".01",
        "authToken": authToken,
        userInfo: user?.info
      }
    };
    try {
      console.log('API Response eeee:', e);
      const response = await axios.post(`${Urls.hrms.UserJobdelegationEmployeeSearch}?location1=${e}`, payload);

      console.log('API Response333:', response?.data); // Log the response for now
      setEmployeeList(
        response?.data?.user?.map((employee) => ({
          id: employee?.uuid,
          name: employee?.name,
          currentRoles: employee?.roles
        }))
      );
      alert('successfully!');
      return response.data;
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to Emp data form.');
    }
  };

  console.log("setFormData",formData?.currentDesignation);
  
  useEffect(() => {
    if (formData?.location1) {
      handleEmployeeResponse(formData?.location1)
      console.log(formData?.location1, "dataMdmsNew");
      setLoading(false); // Set loading to false once the employee list is loaded
    } else {
      setLoading(true); // If no data, keep showing loading
    }
  }, [formData?.location1]);
  console.log("selectedEmployee222",filteredRolesInfor);

  if (loading && isLoadings && filteredRoles) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress /> {/* Show a spinner while loading */}
      </Box>
    );
  }

  return (
    <Card>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ width: '90%', margin: '0 auto', padding: 2 }}
      >
        <Typography variant="h5" sx={{ marginBottom: 3 }}>
          Job Delegation Form
        </Typography>

        <Grid container spacing={2}>
          {/* Department Dropdown */}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="department-label">Department</InputLabel>
              <Select
                labelId="department-label"
                name="departmentId"
                value={formData.departmentId}
                onChange={handleChange}
                label="Department"
              >
                {departmentList.map((department) => (
                  <MenuItem key={department} value={department}>
                    {department}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Location Dropdown */}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="location-label">Location</InputLabel>
              <Select
                labelId="location-label"
                name="location1"
                value={formData.location1}
                onChange={handleChange}
                label="Location"
              >
                {/* {locationList.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))} */}
                {districts.map((district) => (
                  <MenuItem key={location} value={district.name}>
                    {district.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Employee ID Dropdown */}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="empId-label">Employee ID</InputLabel>
              <Select
                labelId="empId-label"
                name="empId"
                value={formData.empId}
                onChange={handleChange}
                label="Employee ID"
              >
                {employeeList.map((employee) => (
                  <MenuItem key={employee.id} value={employee.id}>
                    {employee.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/*Table  Role current */}
          <br />
          <Grid item xs={12} sm={12}>
            {formData.empId && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="employee table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sr. No</TableCell>
                      <TableCell>Employee Name</TableCell>
                      <TableCell>Roles</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formData?.employeeName && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {1} 
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {formData.employeeName} {/* Display employee name */}
                        </TableCell>
                        {filteredRolesInfor.map((role, index) => (
              <TableCell>{role.name}</TableCell>  
            
          ))}
                      </TableRow>
                    )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>



          {/* District Dropdown */}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="district-label">Change of Location</InputLabel>
              <Select
                labelId="district-label"
                name="district"
                value={formData.district}
                onChange={handleChange}
                label="Change of Location"
              >
                {districts.map((district) => (
                  <MenuItem key={district.code} value={district.name}>
                    {district.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Area Dropdown */}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="area-label">Area</InputLabel>
              <Select
                labelId="area-label"
                name="area"
                value={formData.area}
                onChange={handleChange}
                label="Area"
                disabled={!formData.district} // Disable the Area dropdown if no district is selected
              >
                {areas.map((area) => (
                  <MenuItem key={area.code} value={area.name}>
                    {area.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>


          {/* Roles Multi-Select with Search Box */}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="roles-label">Add Role</InputLabel>
              <Select
                labelId="roles-label"
                name="roles"
                multiple // Enable multiple selection
                value={formData?.roles.map(role => role.name)} // Use role names in the value
                onChange={handleChange}
                renderValue={(selected) => selected.join(', ')} // Display selected roles as a comma-separated string
              >
                {filteredRoles?.length > 0 ? ( // Ensure filteredRoles is defined and has elements
                  filteredRoles?.map((role) => (
                    <MenuItem key={role.code} value={role.name}>
                      <Checkbox checked={formData?.roles.some(r => r.name === role.name)} /> {/* Ensure roles are matched by name */}
                      <ListItemText primary={role.name} /> {/* Role label */}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No roles available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          {/* Start Date Field */}
          <Grid item xs={12} sm={4}>
            <TextField
              label="Start Date"
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }} // Ensure the label stays at the top when a date is selected
              fullWidth
            />
          </Grid>

          {/* End Date Field */}
          <Grid item xs={12} sm={4}>
            <TextField
              label="End Date"
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
        </Grid>
        
        <Box sx={{ marginTop: 3 }}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default JobDelegationForm;
